import React from 'react'

export default function TestimonialCard(props) {
    return (
        <div>
            <img src="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/5Stars_120.png?v=1600302123" alt="Trust Pilot 5 Star Review" className=" lazyloaded"></img>
            <div className="text-left" dangerouslySetInnerHTML={{ __html: props.description }}></div> 
        </div>
    )
}
